<template>
  <div>
    <!--<a
      @click="clickTopAd"
      v-if="urlGetParam && data && data.collection && data.collection.attributes.Title == 'Cointelegraph’s Historical Collection'"
      href="https://xgo.com/referral/606e7672c980d972"
      target="_blank"
      style="background: linear-gradient(91.32deg, #d783e8 50%, #7e4cf9 86%); color: #fff"
      class="afterHeaderBlock"
    >
      <div class="afterHeaderBlock__content">
        <img :src="require('@/assets/images/xgo-white.svg')" alt="" class="afterHeaderBlock__logo" />
        <div class="afterHeaderBlock__text">Ready to go beyond with crypto? <b>Ready, set, XGo!</b></div>
        <img :src="require('../../node_modules/mintmade-main/src/assets/images/icons/arrow-right-white.svg')" alt="" class="afterHeaderBlock__arrow" />
      </div>
    </a>-->

    <NftInfo
      v-if="urlGetParam"
      ref="nftInfo"
      :urlGetParam="urlGetParam"
      :contractInfo="contractInfo"
      :price_range="price_range"
      :contracts_abi="contracts_abi"
      :massMediaSettings="massMediaSettings"
      :productContent="productContent"
      :data="data"
      :isUrlValid="isUrlValid"
      :nftModel="nftModel"
      :template="template"
      :shareLink="shareUrl + '?url=' + data.external_url"
      :discordUrl="massMediaSettings.discord"
      :base64Image="base64Image"
      :renderCanvas="true"
      :hasLqip972="true"
      :paymentFlowTitle="'Historical by Cointelegraph'"
      :paymentFlowDesc="paymentFlowDesc"
      :nftInfoTemplate="'template2'"
      :backsideTemplate="'template2'"
      :costFromPrice="true"
      :shareHideTitle="true"
      :copyLinkText="'Copy'"
      :discount="data && data.collection && data.collection.attributes.Title == 'Cointelegraph’s Historical Collection' ? null : null"
      @onSetDataUrl="setDataUrl"
      @onNftModelHasImage="nftModelHasImage"
    >
      <template v-slot:canvasTemplates>
        <Mark1 :data="data" :template="template" :contractInfo="contractInfo" @onRender="setIsRendered" @setImageBase64="nftModelSetImage" v-if="template === 'Mark 1' && Object.keys(data).length" />
        <Mark2
          :data="data"
          :template="template"
          :contractInfo="contractInfo"
          @onRender="setIsRendered"
          @setImageBase64="nftModelSetImage"
          v-else-if="template === 'Mark 2' && Object.keys(data).length"
        />
        <Mark3
          :data="data"
          :template="template"
          :contractInfo="contractInfo"
          @onRender="setIsRendered"
          @onSetImage="nftModelHasImage"
          @setImageBase64="nftModelSetImage"
          v-else-if="template === 'Mark 3' && Object.keys(data).length"
        />
      </template>

      <!--<template v-slot:mintButtonRightBlock v-if="data && data.collection && data.collection.attributes.Title == 'Cointelegraph’s Historical Collection'">
				<div class="sponsoredBy">
					Discount by<a @click="clickLogoAd" href="https://xgo.com/referral/606e7672c980d972" target="_blank"><img :src="require('@/assets/images/xgo-black.svg')" alt=""></a>
				</div>
			</template>-->

      <template v-slot:successContent>
        <div class="bookCall">
          <div class="bookCall__desc">Thank you for your contribution to the Historical Collection!</div>
          <div class="bookCall__quote">
            <div class="bookCall__quote__left">
              <div class="bookCall__quote__image" :style="{ backgroundImage: `url(${require('@/assets/images/bookCall.png')})` }"></div>
            </div>
            <div class="bookCall__quote__right">
              <div class="bookCall__quote__content">
                Hi! I’m founder of <a href="https://mintmade.io/" target="_blank">Mintmade</a> and creator of the Historical! I would love to learn more about your choice and your experience.
                <b>Could we have a call?</b>
              </div>
              <div class="bookCall__quote__buttons">
                <a href="https://calendly.com/mintmade/historical_custdev" target="_blank" class="button big">Book a call</a>
                <a href="https://forms.gle/PQRyyraq22VqQYpA7" target="_blank" class="button big bordered2">Take a survey</a>
              </div>
            </div>
          </div>
        </div>
      </template>
    </NftInfo>

    <InputArticle
      v-if="!urlGetParam"
      title="Collect valuable moments of crypto history"
      :text="`Start building your collection by clicking “Collect this artilce” button in any Cointelegraph article.`"
      :massMediaSettings="massMediaSettings"
      :type="'template3'"
      buttonExploreText="Explore Cointelegraph"
      buttonExploreLink="https://cointelegraph.com"
      buttonUrlText="Paste article URL"
      buttonText="Mint this article into NFT"
      :backImage="require('@/assets/images/inputBack.png?placeholder')"
    >
      <template v-slot:afterForm>
        <div class="choose_news">
          <div class="choose_news--delitimer">or</div>
          <div class="choose_news--text">Collect one of the latest articles:</div>
          <ChooseNews />
        </div>
      </template>
    </InputArticle>

    <JustMinted
      :count="6"
      :title="urlGetParam ? 'Recently collected' : 'Just minted'"
      :linkText="'Explore marketplace'"
      :linkIcon="require('@/assets/images/icons/arrow-right-dark2.svg')"
      :linkUrl="`${openseaUrl}/collection/${openseaCollection}`"
    />

    <About
      v-if="$store.getters.getIsTop100Page"
      template="template2"
      :content="about"
      :aboutText="'Tens of thousands of people have been killed and many more injured after a 7.8 magnitude earthquake struck southern Turkey on Monday, February 6. <b>All proceed from this sale will be donated to International Rescue Committee.</b>'"
      title="<span>Top 100</span> to help after the Syria-Turkey earthquake"
      :image="require('@/assets/images/turkey.jpg?placeholder')"
      :buttonText="'About initiative'"
      :linkUrl="'https://www.rescue.org/article/how-help-after-syria-turkey-earthquake'"
    />

    <About
      template="template2"
      :content="about"
      :aboutText="aboutText"
      v-else
      title="Collect Cointelegraph <br /> <span>articles as NFTs</span>"
      :video="require('@/assets/videos/video.mp4')"
      :videoImage="require('@/assets/videos/video.gif')"
      :linkUrl="'https://cointelegraph.com/historical/'"
      :features="[
        { title: '100%', text: 'Official Collectibles' },
        { title: '1100', text: 'Items Collected' },
        { title: '464', text: 'Unique Owners' },
      ]"
    />

    <Features
      title="Why collect news?"
      :features="[
        { text: 'Turn news into historical artefacts and own pivotal moments of blockchain history.', number: 1 },
        { text: 'There is no explanation for it, I just wanted to and bought it, without any purpose' },
        { text: 'Cointelegraph’s artworks are symbols of crypto. Each article illustration is a hand-made artwork.', number: 2 },
        { text: 'Have always wanted to start collecting these article NFTs' },
        { text: 'The stories you collect are automatically displayed on the main page of Cointelegraph – highlight what’s valuable.', number: 3 },
        { text: 'I’m a founder of the project, going to raffle this to the community.' },
      ]"
    />

    <ChooseNews
      v-if="urlGetParam"
      class="container container--small choose_news__container"
      :title="'Latest news'"
      :linkText="'Explore Cointelegraph'"
      :linkUrl="'https://cointelegraph.com/'"
      :linkIcon="require('@/assets/images/icons/arrow-right-dark2.svg')"
    />

    <!--<Banner :title="'Welcome to the club'" :url="massMediaSettings.discord"
			:image="require('@/assets/images/icons/discord.svg')">
			<template v-slot:desc>
				Discuss latest news, impact the project development<br> or shill with other collectors
			</template>
		</Banner>-->

    <Faq :faqContent="faqContent" />
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

import massMediaSettings from "@/assets/json/massMedia/settings.json";
import about from "@/assets/json/massMedia/about.json";
import faqContent from "@/assets/json/productPage/faq.json";
import contractInfo from "@/assets/json/contract_info.json";
import { Nft, Api } from "mintmade-main";
import { useMeta } from "vue-meta";
import price_range from "@/assets/json/price_range.json";
import contracts_abi from "@/assets/json/contractsAbi.json";
import productContent from "@/assets/json/productPage/productInfo.json";

const NftInfo = defineAsyncComponent(() => import("../../node_modules/mintmade-main/src/components/NftInfo/NftInfo.vue"));
const Mark1 = defineAsyncComponent(() => import("@/components/ImagesTemplates/Mark1.vue"));
const Mark2 = defineAsyncComponent(() => import("@/components/ImagesTemplates/Mark2.vue"));
const Mark3 = defineAsyncComponent(() => import("@/components/ImagesTemplates/Mark3.vue"));
const Faq = defineAsyncComponent(() => import("../../node_modules/mintmade-main/src/components/Faq/Faq.vue"));
const InputArticle = defineAsyncComponent(() => import("../../node_modules/mintmade-main/src/components/InputArticle/InputArticle.vue"));
const About = defineAsyncComponent(() => import("../../node_modules/mintmade-main/src/components/About/About.vue"));
/*const Banner = defineAsyncComponent(() =>
	import("../../node_modules/mintmade-main/src/components/Banner/Banner.vue")
)*/
const JustMinted = defineAsyncComponent(() => import("../../node_modules/mintmade-main/src/components/JustMinted/JustMinted.vue"));
const ChooseNews = defineAsyncComponent(() => import("../../node_modules/mintmade-main/src/components/ChooseNews/ChooseNews.vue"));
const Features = defineAsyncComponent(() => import("../../node_modules/mintmade-main/src/components/Features/Features.vue"));
// import ChooseNews from '@/components/ChooseNews/ChooseNews.vue';
import aboutText from "@/assets/content/about.md";

export default {
  name: "NftPage",
  setup() {
    useMeta({ title: "Be the first to mint hot stories" });
  },
  emits: ["buyNft", "closePaymentMethod", "buyWithNft"],
  data: () => ({
    massMediaSettings,
    base64Image: "",
    productContent,
    faqContent,
    contractInfo,
    price_range,
    openseaUrl: null,
    openseaCollection: null,
    urlGetParam: "",
    external_url: "",
    contracts_abi,
    template: "",
    about,
    aboutText,
    paymentFlowDesc: "",
    data: {
      id: "",
      name: "",
      description: "",
      article_image: "",
      external_url: "",
      author: "",
      date: "",
      published_date: "",
      category: "",
      template: "",
      logo: "",
    },
    nftInfo: null,
    isUrlValid: false,
    pageTitle: "Be the first to mint hot stories",
    openGraphTitle: null,
    openGraphDescription: null,
    nftModel: null,
    shareUrl: "",
    setedDataUrl: false,
  }),
  components: {
    NftInfo,
    Mark1,
    Mark2,
    Mark3,
    Faq,
    About,
    InputArticle,
    //Banner,
    JustMinted,
    ChooseNews,
    Features,
  },
  methods: {
    nftModelHasImage() {
      this.nftModel.hasImageS3 = true;
    },
    nftModelSetImage(value) {
      this.base64Image = value;
    },
    buyWithNft() {
      this.$emit("buyWithNft");
    },
    buyNft() {
      this.$emit("buyNft");
    },
    closePaymentMethod() {
      this.$emit("closePaymentMethod");
    },
    handleRender() {
      this.$refs.nftInfo.handleRender();
    },
    setIsRendered(isRendered) {
      this.$refs.nftInfo.setIsRendered(isRendered);
    },
    clickLogoAd() {
      window.amplitude.getInstance().logEvent("click logo ad");
    },
    clickTopAd() {
      window.amplitude.getInstance().logEvent("click top ad");
    },
    checkUrlValid() {
      const { external_url } = this.data;
      if (external_url) {
        try {
          this.isUrlValid = new URL(external_url).hostname === this.massMediaSettings.mediaUrl;
          window.amplitude.getInstance().logEvent("open minting page");
        } catch {
          this.isUrlValid = false;
        }
      } else {
        this.isUrlValid = false;
      }
    },
    setDataUrl(external_url) {
      const $this = this;

      if (!this.setedDataUrl) {
        $this.setedDataUrl = true;
        setTimeout(function () {
          $this.setedDataUrl = false;
        }, 1);
        this.data.external_url = external_url;
        this.checkUrlValid();
        this.getArticleContent(external_url);
      }
    },
    async getArticleContent(url) {
      this.data = {};
      this.nftInfo = null;
      this.isRendered = false;

      if (this.isUrlValid) {
        try {
          await this.getNftId(url);

          if (this.nftInfo) {
            if (this.nftInfo.attributes.NFTTemplate === "TOP100") {
              this.$store.commit("SET_IS_TOP100_PAGE", true);
            } else {
              this.$store.commit("SET_IS_TOP100_PAGE", false);
            }

            this.template = this.nftInfo.attributes.NFTTemplate;
            this.data.template = this.template;

            let imageFormat = "jpeg";

            if (this.nftInfo.attributes.NFTTemplate === "Ticket") imageFormat = "png";

            this.data = await Api.getPageData(url, this.nftInfo, true, true, imageFormat);

            this.paymentFlowDesc = this.data.name;

            let date = new Date(this.data.published_date * 1000);

            const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

            this.pageTitle = 'Mint "' + this.data.title + '"';

            this.openGraphTitle = "Look what I’ve just minted into an NFT";
            this.openGraphDescription = "Just minted an NFT of Cointelegraph article #" + this.data.id + " from Thu, " + date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear();
          } else {
            this.isUrlValid = false;
            this.data = {};
          }
        } catch {
          this.isUrlValid = false;
        }

        //this.template = this.massMediaSettings.template;
        //this.data.template = this.template;
        this.data.logo = require(`@/assets/images${this.massMediaSettings.canvasLogo}`);
      }
    },
    async getNftId(url) {
      this.nftInfo = await Api.getNftId(url);

      if (this.nftInfo != null && this.nftInfo.ID != null /*&& this.nftInfo.coverURL*/ && this.nftInfo.mediaID && this.nftInfo.title) {
        const nftId = this.nftInfo.ID;
        this.$store.getters.getWallet.currentNftId = nftId;
        this.contractInfo.nftId = nftId;

        this.nftModel.updateNftId(nftId, this.nftInfo);
      } else {
        this.nftInfo = null;
      }
    },
  },
  watch: {
    $route(to, from) {
      if (to.path === "/" && from.path === "/") {
        this.contractInfo.nftId = null;
        this.nftModel = new Nft();
        this.nftModel.contracts_abi = this.contracts_abi;

        this.$store.commit("SET_IS_TOP100_PAGE", false);
        const url = new URL(window.location.href);
        const externalUrl = url.searchParams.get("url");

        if (externalUrl) {
          this.setDataUrl(externalUrl);
        } else {
          this.data.external_url = null;
          this.pageTitle = "Be the first to mint hot stories";

          this.openGraphTitle = null;
          this.openGraphDescription = null;
        }
      }
    },
    isUrlValid() {
      if (!this.isUrlValid) {
        this.pageTitle = "Be the first to mint hot stories";

        this.openGraphTitle = null;
        this.openGraphDescription = null;
      }
    },
    pageTitle() {
      document.title = this.pageTitle;
    },
    openGraphTitle() {
      if (this.openGraphTitle) {
        if (document.querySelector("[property='og:title']")) {
          document.querySelector("[property='og:title']").content = this.openGraphTitle;
        } else {
          const meta = document.createElement("meta");
          meta.setAttribute("property", "og:title");
          meta.content = this.openGraphTitle;
          document.getElementsByTagName("head")[0].appendChild(meta);
        }
      } else {
        document.querySelector("[property='og:title']").content = "";
      }
    },
    openGraphDescription() {
      if (this.openGraphDescription) {
        if (document.querySelector("[property='og:description']")) {
          document.querySelector("[property='og:description']").content = this.openGraphDescription;
        } else {
          const meta = document.createElement("meta");
          meta.setAttribute("property", "og:description");
          meta.content = this.openGraphDescription;
          document.getElementsByTagName("head")[0].appendChild(meta);
        }
      } else {
        document.querySelector("[property='og:description']").content = "";
      }
    },
  },
  mounted() {
    this.openseaUrl = process.env.VUE_APP_OPENSEA_URL;
    this.openseaCollection = process.env.VUE_APP_OPENSEA_COLLECTION;
    this.shareUrl = process.env.VUE_APP_SHARE_URL;

    this.nftModel = new Nft();
    this.nftModel.contracts_abi = this.contracts_abi;
  },
  async created() {
    const url = new URL(window.location.href);
    const externalUrl = url.searchParams.get("url");

    this.urlGetParam = externalUrl;
  },
  beforeUpdate() {
    const url = new URL(window.location.href);
    const externalUrl = url.searchParams.get("url");

    this.urlGetParam = externalUrl;
  },
  beforeUnmount() {
    this.$store.commit("SET_IS_TOP100_PAGE", false);
    document.querySelector("[property='og:title']").content = "";
    document.querySelector("[property='og:description']").content = "";
    window.removeEventListener("resize", this.setLineWidth);
  },
};
</script>
